.aboutPageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 500;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  .aboutBtnContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: 100px;
    justify-content: center;
    align-items: center;

    .aboutBtn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0px 20px 0px 20px;
      background-color: #20638c;
      border-style: outset;
      border-width: 5px;
      border-radius: 10px;
      width: 165px;
      height: 60px;
      font-size: 20px;
      font-family: sans-serif;
      font-weight: bold;
      text-align: center;
      color: white;
      text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
        1px 1px 1px rgba(0, 0, 0, 0.5);
      text-decoration: none;
      cursor: pointer;
      outline: none;
    }

    .aboutBtn:hover {
      transform: scale(1.06);
    }

    .aboutBtn:active {
      border-style: inset;
      border-width: 5px;
      text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
        1px 1px 1px rgba(255, 255, 255, 0.1);
    }
  }

  .hideContainer {
    display: none;
  }

  .showBioContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;

    .bioTitleContainer {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .bioTitle {
        font-family: sans-serif;
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #20638c;
      }
    }

    .mobileBioSectionContainer {
      display: none;
    }

    .bioSectionContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .bioImgContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .bioImg {
          height: 400px;
          border-radius: 15px;
        }
      }
      .bioParagraphContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        margin: 0px 15px;
        width: 70%;

        .bioParagraph {
          margin: 10px 0px;
          line-height: 40px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileBioSectionContainer2 {
      display: none;
    }

    .bioSectionContainer2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .bioImgContainer2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .bioImg2 {
          height: 400px;
          border-radius: 15px;
        }
      }
      .bioParagraphContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        margin: 15px 0px;
        width: 90%;

        .bioParagraph2 {
          margin: 10px 0px;
          line-height: 40px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 90%;
    height: 5px;
    border-radius: 5px;
    background-color: #20638c;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }

  .showPhilanthropyContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;

    .philanthropyTitleContainer {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .philanthropyTitle {
        font-family: sans-serif;
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #20638c;
      }
    }

    .mobilePhilanthropySectionContainer {
      display: none;
    }

    .philanthropySectionContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .philanthropyImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 25px;
        width: 450px;

        .philanthropyImg {
          width: 100%;
          border-radius: 15px;
        }

        .imgRow1 > .philanthropyImg {
          width: 49%;
          margin: 0 1% 0 0;
        }

        .imgRow2 > .philanthropyImg {
          width: 99%;
          margin: 0.5% 1% 0 0;
        }
      }

      .philanthropyImgContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 25px;
        width: 250px;

        .philanthropyImg2 {
          width: 100%;
          border-radius: 15px;
        }
      }

      .philanthropyParagraphContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        width: 60%;
        margin: 15px;

        .philanthropyParagraph {
          margin: 10px 0px;
          line-height: 40px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }
  }

  .showAppointmentsContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;

    .currentAppointmentsTitleContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;

      .currentAppointmentsTitle {
        font-family: sans-serif;
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #20638c;
        text-align: center;
      }
    }

    .currentAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin-top: 25px;

      .singleAppointmentContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .appointmentGroup {
          margin: 10px 0px;
          font-family: sans-serif;
          font-size: 25px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
        }

        .appointmentTitle {
          margin: 10px 0px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: center;
          letter-spacing: 1px;
        }

        .appointmentDate {
          margin: 10px 0px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: center;
          letter-spacing: 1px;
        }

        .appointmentDescription {
          margin: 10px 0px;
          line-height: 30px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: center;
          letter-spacing: 1px;
          color: red;
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        border-radius: 5px;
        background-color: #20638c;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
        margin: 20px 0px 20px 0px;
      }
    }

    .mobileAppointmentsContainer {
      display: none;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .aboutPageContainer {
    .showBioContainer {
      .bioTitleContainer {
        .bioTitle {
          font-size: 40px;
        }
      }

      .bioSectionContainer {
        .bioImgContainer {
          .bioImg {
            height: 300px;
          }
        }
        .bioParagraphContainer {
          .bioParagraph {
            line-height: 30px;
            font-size: 20px;
          }
        }
      }

      .bioSectionContainer2 {
        .bioImgContainer2 {
          .bioImg2 {
            height: 300px;
          }
        }
        .bioParagraphContainer2 {
          .bioParagraph2 {
            line-height: 30px;
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      .philanthropyTitleContainer {
        .philanthropyTitle {
          font-size: 40px;
        }
      }

      .philanthropySectionContainer {
        .philanthropyImgContainer {
          margin: 15px;
          width: 350px;
        }

        .philanthropyImgContainer2 {
          margin: 15px;
          width: 250px;
        }

        .philanthropyParagraphContainer {
          .philanthropyParagraph {
            line-height: 30px;
            font-size: 20px;
          }
        }
      }
    }

    .showAppointmentsContainer {
      .currentAppointmentsTitleContainer {
        .currentAppointmentsTitle {
          font-size: 40px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .aboutPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 500;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    .aboutBtnContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
      height: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .aboutBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 20px 0px 20px;
        background-color: #20638c;
        border-style: outset;
        border-width: 5px;
        border-radius: 10px;
        width: 165px;
        height: 60px;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        color: white;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
          1px 1px 1px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        cursor: pointer;
        outline: none;
      }

      .aboutBtn:hover {
        transform: scale(1.06);
      }

      .aboutBtn:active {
        border-style: inset;
        border-width: 5px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
          1px 1px 1px rgba(255, 255, 255, 0.1);
      }
    }

    .hideContainer {
      display: none;
    }

    .showBioContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .bioTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .bioTitle {
          font-family: sans-serif;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobileBioSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 15px;

          .mobileBioImg {
            height: 400px;
            border-radius: 15px;
            margin: 15px;
          }
        }
        .mobileBioParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 0px 15px;
          width: 90%;

          .mobileBioParagraph {
            margin: 10px 0px;
            line-height: 35px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer {
        display: none;
      }

      .mobileBioSectionContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 15px;

          .mobileBioImg2 {
            height: 275px;
            border-radius: 15px;
          }
        }
        .mobileBioParagraphContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 15px 0px;
          width: 90%;

          .mobileBioParagraph2 {
            margin: 10px 0px;
            line-height: 35px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer2 {
        display: none;
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .philanthropyTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .philanthropyTitle {
          font-family: sans-serif;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobilePhilanthropySectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobilePhilanthropyImgContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px;
          width: 400px;

          .mobilePhilanthropyImg {
            width: 100%;
            border-radius: 15px;
          }

          .mobileImgRow1 > .mobilePhilanthropyImg {
            width: 49%;
            margin: 0 1% 0 0;
          }

          .mobileImgRow2 > .mobilePhilanthropyImg {
            width: 99%;
            margin: 0.5% 1% 0 0;
          }
        }

        .mobilePhilanthropyImgContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px;
          width: 250px;

          .mobilePhilanthropyImg2 {
            width: 100%;
            border-radius: 15px;
          }
        }

        .mobilePhilanthropyParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 90%;
          margin: 15px;

          .mobilePhilanthropyParagraph {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .philanthropySectionContainer {
        display: none;
      }
    }

    .showAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .currentAppointmentsTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .currentAppointmentsTitle {
          font-family: sans-serif;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .currentAppointmentsContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        .singleAppointmentContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .appointmentGroup {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentTitle {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDate {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDescription {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
            color: red;
          }
        }

        .separator {
          display: flex;
          position: relative;
          width: 90%;
          height: 5px;
          border-radius: 5px;
          background-color: #20638c;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
          margin: 20px 0px 20px 0px;
        }
      }

      .mobileAppointmentsContainer {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .aboutPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 500;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    .aboutBtnContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
      height: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      .aboutBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 20px 0px 20px;
        background-color: #20638c;
        border-style: outset;
        border-width: 5px;
        border-radius: 10px;
        width: 165px;
        height: 60px;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        color: white;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
          1px 1px 1px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        cursor: pointer;
        outline: none;
      }

      .aboutBtn:hover {
        transform: scale(1.06);
      }

      .aboutBtn:active {
        border-style: inset;
        border-width: 5px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
          1px 1px 1px rgba(255, 255, 255, 0.1);
      }
    }

    .hideContainer {
      display: none;
    }

    .showBioContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .bioTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .bioTitle {
          font-family: sans-serif;
          font-size: 35px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobileBioSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 15px;

          .mobileBioImg {
            height: 300px;
            border-radius: 15px;
            margin: 15px;
          }
        }
        .mobileBioParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 0px 15px;
          width: 90%;

          .mobileBioParagraph {
            margin: 10px 0px;
            line-height: 35px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer {
        display: none;
      }

      .mobileBioSectionContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 15px;

          .mobileBioImg2 {
            height: 200px;
            border-radius: 15px;
          }
        }
        .mobileBioParagraphContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 15px 0px;
          width: 90%;

          .mobileBioParagraph2 {
            margin: 10px 0px;
            line-height: 35px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer2 {
        display: none;
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .philanthropyTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .philanthropyTitle {
          font-family: sans-serif;
          font-size: 35px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobilePhilanthropySectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobilePhilanthropyImgContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px;
          width: 400px;

          .mobilePhilanthropyImg {
            width: 100%;
            border-radius: 15px;
          }

          .mobileImgRow1 > .mobilePhilanthropyImg {
            width: 49%;
            margin: 0 1% 0 0;
          }

          .mobileImgRow2 > .mobilePhilanthropyImg {
            width: 99%;
            margin: 0.5% 1% 0 0;
          }
        }

        .mobilePhilanthropyImgContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px;
          width: 250px;

          .mobilePhilanthropyImg2 {
            width: 100%;
            border-radius: 15px;
          }
        }

        .mobilePhilanthropyParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 90%;
          margin: 15px;

          .mobilePhilanthropyParagraph {
            margin: 10px 0px;
            line-height: 35px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .philanthropySectionContainer {
        display: none;
      }
    }

    .showAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .currentAppointmentsTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .currentAppointmentsTitle {
          font-family: sans-serif;
          font-size: 35px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .currentAppointmentsContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 25px;

        .singleAppointmentContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .appointmentGroup {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 25px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentTitle {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDate {
            margin: 10px 0px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDescription {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
            color: red;
          }
        }

        .separator {
          display: flex;
          position: relative;
          width: 90%;
          height: 5px;
          border-radius: 5px;
          background-color: #20638c;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
          margin: 20px 0px 20px 0px;
        }
      }

      .mobileAppointmentsContainer {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .aboutPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 500;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    .aboutBtnContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
      height: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .aboutBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 5px 0px 5px;
        background-color: #20638c;
        border-style: outset;
        border-width: 5px;
        border-radius: 10px;
        width: 140px;
        height: 60px;
        font-size: 18px;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        color: white;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
          1px 1px 1px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        cursor: pointer;
        outline: none;
      }

      .aboutBtn:hover {
        transform: scale(1.025);
      }

      .aboutBtn:active {
        border-style: inset;
        border-width: 5px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
          1px 1px 1px rgba(255, 255, 255, 0.1);
      }
    }

    .hideContainer {
      display: none;
    }

    .showBioContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .bioTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .bioTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobileBioSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg {
            height: 250px;
            border-radius: 10px;
            margin: 7.5px;
          }
        }
        .mobileBioParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 0px 10px;
          width: 90%;

          .mobileBioParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer {
        display: none;
      }

      .mobileBioSectionContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg2 {
            height: 140px;
            border-radius: 10px;
          }
        }
        .mobileBioParagraphContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 7.5px 0px;
          width: 90%;

          .mobileBioParagraph2 {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer2 {
        display: none;
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .philanthropyTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .philanthropyTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
        }
      }

      .mobilePhilanthropySectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobilePhilanthropyImgContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px 0px 7.5px 0px;
          width: 350px;

          .mobilePhilanthropyImg {
            width: 100%;
            border-radius: 10px;
          }

          .mobileImgRow1 > .mobilePhilanthropyImg {
            width: 49%;
            margin: 0 1% 0 0;
          }

          .mobileImgRow2 > .mobilePhilanthropyImg {
            width: 99%;
            margin: 0.5% 1% 0 0;
          }
        }

        .mobilePhilanthropyImgContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 7.5px;
          width: 250px;

          .mobilePhilanthropyImg2 {
            width: 100%;
            border-radius: 10px;
          }
        }

        .mobilePhilanthropyParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 90%;
          margin: 7.5px;

          .mobilePhilanthropyParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .philanthropySectionContainer {
        display: none;
      }
    }

    .showAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .currentAppointmentsTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .currentAppointmentsTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
          text-align: center;
          line-height: 40px;
        }
      }

      .currentAppointmentsContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .singleAppointmentContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .appointmentGroup {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentTitle {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDate {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDescription {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
            color: red;
          }
        }

        .separator {
          display: flex;
          position: relative;
          width: 90%;
          height: 5px;
          border-radius: 5px;
          background-color: #20638c;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
          margin: 10px 0px 10px 0px;
        }
      }

      .mobileAppointmentsContainer {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .aboutPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 500;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    .aboutBtnContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
      height: 80px;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .aboutBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 2px 0px 2px;
        background-color: #20638c;
        border-style: outset;
        border-width: 5px;
        border-radius: 10px;
        width: 115px;
        height: 60px;
        font-size: 15px;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        color: white;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
          1px 1px 1px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        cursor: pointer;
        outline: none;
      }

      .aboutBtn:hover {
        transform: scale(1.025);
      }

      .aboutBtn:active {
        border-style: inset;
        border-width: 5px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
          1px 1px 1px rgba(255, 255, 255, 0.1);
      }
    }

    .hideContainer {
      display: none;
    }

    .showBioContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .bioTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .bioTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobileBioSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg {
            height: 250px;
            border-radius: 10px;
            margin: 7.5px;
          }
        }
        .mobileBioParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 0px 10px;
          width: 90%;

          .mobileBioParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer {
        display: none;
      }

      .mobileBioSectionContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg2 {
            height: 125px;
            border-radius: 10px;
          }
        }
        .mobileBioParagraphContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 7.5px 0px;
          width: 90%;

          .mobileBioParagraph2 {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer2 {
        display: none;
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .philanthropyTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .philanthropyTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
          text-align: center;
          line-height: 36px;
        }
      }

      .mobilePhilanthropySectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobilePhilanthropyImgContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px 0px 7.5px 0px;
          width: 325px;

          .mobilePhilanthropyImg {
            width: 100%;
            border-radius: 10px;
          }

          .mobileImgRow1 > .mobilePhilanthropyImg {
            width: 49%;
            margin: 0 1% 0 0;
          }

          .mobileImgRow2 > .mobilePhilanthropyImg {
            width: 99%;
            margin: 0.5% 1% 0 0;
          }
        }

        .mobilePhilanthropyImgContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 7.5px;
          width: 250px;

          .mobilePhilanthropyImg2 {
            width: 100%;
            border-radius: 10px;
          }
        }

        .mobilePhilanthropyParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 90%;
          margin: 7.5px;

          .mobilePhilanthropyParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .philanthropySectionContainer {
        display: none;
      }
    }

    .showAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .currentAppointmentsTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .currentAppointmentsTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
          text-align: center;
          line-height: 36px;
        }
      }

      .currentAppointmentsContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .singleAppointmentContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .appointmentGroup {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 20px;
            line-height: 26px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentTitle {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDate {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDescription {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
            color: red;
          }
        }

        .separator {
          display: flex;
          position: relative;
          width: 90%;
          height: 5px;
          border-radius: 5px;
          background-color: #20638c;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
          margin: 10px 0px 10px 0px;
        }
      }

      .mobileAppointmentsContainer {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .aboutPageContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 500;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    .aboutBtnContainer {
      display: flex;
      position: relative;
      flex-direction: row;
      width: 100%;
      height: 80px;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      .aboutBtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 2px 0px 2px;
        background-color: #20638c;
        border-style: outset;
        border-width: 5px;
        border-radius: 10px;
        width: 100px;
        height: 60px;
        font-size: 13px;
        font-family: sans-serif;
        font-weight: bold;
        text-align: center;
        color: white;
        text-shadow: -1px -1px 1px rgba(255, 255, 255, 0.1),
          1px 1px 1px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        cursor: pointer;
        outline: none;
      }

      .aboutBtn:hover {
        transform: scale(1.025);
      }

      .aboutBtn:active {
        border-style: inset;
        border-width: 5px;
        text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.5),
          1px 1px 1px rgba(255, 255, 255, 0.1);
      }
    }

    .hideContainer {
      display: none;
    }

    .showBioContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 95%;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .bioTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .bioTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .mobileBioSectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg {
            height: 250px;
            border-radius: 10px;
            margin: 7.5px;
          }
        }
        .mobileBioParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 0px 10px;
          width: 90%;

          .mobileBioParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer {
        display: none;
      }

      .mobileBioSectionContainer2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobileBioImgContainer2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin: 7.5px;

          .mobileBioImg2 {
            height: 110px;
            border-radius: 10px;
          }
        }
        .mobileBioParagraphContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          margin: 7.5px 0px;
          width: 90%;

          .mobileBioParagraph2 {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .bioSectionContainer2 {
        display: none;
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .showPhilanthropyContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .philanthropyTitleContainer {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;

        .philanthropyTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
          text-align: center;
          line-height: 36px;
        }
      }

      .mobilePhilanthropySectionContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .mobilePhilanthropyImgContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px 0px 7.5px 0px;
          width: 300px;

          .mobilePhilanthropyImg {
            width: 100%;
            border-radius: 10px;
          }

          .mobileImgRow1 > .mobilePhilanthropyImg {
            width: 49%;
            margin: 0 1% 0 0;
          }

          .mobileImgRow2 > .mobilePhilanthropyImg {
            width: 99%;
            margin: 0.5% 1% 0 0;
          }
        }

        .mobilePhilanthropyImgContainer2 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 7.5px;
          width: 250px;

          .mobilePhilanthropyImg2 {
            width: 100%;
            border-radius: 10px;
          }
        }

        .mobilePhilanthropyParagraphContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          width: 90%;
          margin: 7.5px;

          .mobilePhilanthropyParagraph {
            margin: 7.5px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: left;
            letter-spacing: 1px;
          }
        }
      }

      .philanthropySectionContainer {
        display: none;
      }
    }

    .showAppointmentsContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 10px 0px;

      .currentAppointmentsTitleContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;

        .currentAppointmentsTitle {
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 1px;
          color: #20638c;
          text-align: center;
          line-height: 36px;
        }
      }

      .currentAppointmentsContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 10px;

        .singleAppointmentContainer {
          display: flex;
          flex-direction: column;
          position: relative;
          justify-content: center;
          align-items: center;

          .appointmentGroup {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            line-height: 24px;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentTitle {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDate {
            margin: 7.5px 0px;
            font-family: sans-serif;
            font-size: 18px;
            text-align: center;
            letter-spacing: 1px;
          }

          .appointmentDescription {
            margin: 10px 0px;
            line-height: 30px;
            font-family: sans-serif;
            font-size: 20px;
            text-align: center;
            letter-spacing: 1px;
            color: red;
          }
        }

        .separator {
          display: flex;
          position: relative;
          width: 90%;
          height: 5px;
          border-radius: 5px;
          background-color: #20638c;
          box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
          margin: 10px 0px 10px 0px;
        }
      }

      .mobileAppointmentsContainer {
        display: none;
      }
    }
  }
}
