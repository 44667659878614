.headerContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0px 10px 0px 10px;
  z-index: 990;

  .siteLogoContainer {
    display: flex;
    position: relative;
    margin-left: 10px;

    .siteLogo {
      display: flex;
      position: relative;
      z-index: 999;
      height: 70px;
    }
  }

  .menuContainer {
    display: flex;
    position: relative;
    z-index: 999;

    .navLinks {
      margin-right: 25px;
      font-size: 24px;
      font-family: sans-serif;
      font-weight: bold;
      color: #20638c;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      outline: none;
    }

    .navLinks:hover {
      font-size: 27px;
    }

    .active {
      margin-right: 25px;
      font-size: 24px;
      font-family: sans-serif;
      font-weight: bold;
      font-style: italic;
      color: #a61f38;
      text-decoration: none;
      text-align: center;
      cursor: pointer;
      outline: none;
    }

    .donateBtn {
      margin-right: 10px;
      font-size: 24px;
      font-family: sans-serif;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      color: #1c9986;
      cursor: pointer;
      outline: none;
    }

    .donateBtn:hover {
      font-size: 27px;
    }
  }

  .mobileMenuContainer {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .headerContainer {
    .menuContainer {
      .navLinks {
        margin-right: 15px;
        font-size: 20px;
      }

      .navLinks:hover {
        font-size: 22px;
      }

      .donateBtn {
        font-size: 20px;
      }

      .donateBtn:hover {
        font-size: 22px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .headerContainer {
    flex-direction: column;
    justify-content: center;

    .siteLogoContainer {
      margin-left: 0px;
      padding: 5px 0px;
      width: 100vw;
      background-color: white;
      justify-content: center;
      z-index: 995;

      .siteLogo {
        height: 60px;
      }
    }

    .menuContainer {
      display: none;
    }

    .mobileMenuContainer {
      display: flex;
      width: 100vw;
      justify-content: center;
      align-items: center;

      .mobileMenuBtnContainer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;

        .mobileMenuBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 35px;
          background-color: #20638c;
          font-size: 24px;
          color: white;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
          text-decoration: none;
          border: none;
          outline: none;
          z-index: 999;
          cursor: pointer;
        }
      }

      .showMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        z-index: 980;
        width: 100vw;
        top: 10px;
        transition: 0.75s;
        transform: translate(0, 92px);
      }

      .showMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 8px;
      }

      .showMobileMenu ul li {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
      }

      .showMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .showMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .hideMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        z-index: 980;
        top: 32px;
        transform: translate(0, -200px);
        transition: 0.75s;
      }

      .hideMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 10px;
      }

      .hideMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }

      .hideMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .headerContainer {
    flex-direction: column;
    justify-content: center;

    .siteLogoContainer {
      margin-left: 0px;
      padding: 5px 0px;
      width: 100vw;
      background-color: white;
      justify-content: center;
      z-index: 995;

      .siteLogo {
        height: 60px;
      }
    }

    .menuContainer {
      display: none;
    }

    .mobileMenuContainer {
      display: flex;
      width: 100vw;
      justify-content: center;
      align-items: center;

      .mobileMenuBtnContainer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;

        .mobileMenuBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 35px;
          background-color: #20638c;
          font-size: 24px;
          color: white;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
          text-decoration: none;
          border: none;
          outline: none;
          z-index: 999;
          cursor: pointer;
        }
      }

      .showMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        z-index: 980;
        width: 100vw;
        top: 10px;
        transition: 0.75s;
        transform: translate(0, 92px);
      }

      .showMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 8px;
      }

      .showMobileMenu ul li {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
      }

      .showMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .showMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .hideMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        z-index: 980;
        top: 32px;
        transform: translate(0, -200px);
        transition: 0.75s;
      }

      .hideMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 10px;
      }

      .hideMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }

      .hideMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .headerContainer {
    flex-direction: column;
    justify-content: center;

    .siteLogoContainer {
      margin-left: 0px;
      padding: 5px 0px;
      width: 100vw;
      background-color: white;
      justify-content: center;
      z-index: 995;

      .siteLogo {
        height: 60px;
      }
    }

    .menuContainer {
      display: none;
    }

    .mobileMenuContainer {
      display: flex;
      width: 100vw;
      justify-content: center;
      align-items: center;

      .mobileMenuBtnContainer {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 100%;

        .mobileMenuBtn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 35px;
          background-color: #20638c;
          font-size: 24px;
          color: white;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
          text-decoration: none;
          border: none;
          outline: none;
          z-index: 999;
          cursor: pointer;
        }
      }

      .showMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        z-index: 980;
        width: 100vw;
        top: 10px;
        transition: 0.75s;
        transform: translate(0, 92px);
      }

      .showMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 8px;
      }

      .showMobileMenu ul li {
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
      }

      .showMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .showMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
        cursor: pointer;
      }

      .hideMobileMenu {
        display: flex;
        position: absolute;
        justify-content: center;
        flex-direction: column;
        width: 100vw;
        z-index: 980;
        top: 32px;
        transform: translate(0, -200px);
        transition: 0.75s;
      }

      .hideMobileMenu ul {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: black;
        padding-bottom: 10px;
      }

      .hideMobileMenu ul li a {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #20638c;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }

      .hideMobileMenu ul li .donateBtn {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #1c9986;
        font-family: sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 2px;
        text-decoration: none;
        outline: none;
      }
    }
  }
}
