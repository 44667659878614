.homepageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;

  .homepageHeaderContainer {
    margin: 0px 0px 25px 0px;


  .base-timer {
  position: relative;
  width: 300px;
  height: 300px;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 8px;
  stroke: grey;
}

.base-timer__path-remaining {
  stroke-width: 6px;
  stroke-linecap: butt;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
}



    .countdownMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 200px;
      background-image: url('../../images/countdownFlag.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      justify-content: center;
      align-items: center;

      .voteCountdownContainer {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 10px 0px;

        .hideCountdown {
          display: none;
        }

        .counterMainContainer {
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100px;
          margin: 0px 10px 0px 10px;
          background-color: blue;
          border-style: solid;
          border-width: 1.5px;
          border-color: white;
          border-radius: 10px;

          .counterContainer {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 60px;
            background-color: red;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 5px;
            margin: 10px 0px;

            .counterNum {
              font-family: sans-serif;
              font-size: 36px;
              color: white;
            }
          }

          .counterTitleContainer {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;

            .counterTitle {
              font-size: 24px;
              color: white;
            }
          }
        }
      }

      .voteMsgContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 775px;
        height: 36px;
        margin: 0px 10px 0px 10px;
        background-color: blue;
        border-style: solid;
        border-width: 1.5px;
        border-color: white;
        border-radius: 10px;

        .voteMsg {
          flex-wrap: wrap;
          text-align: center;
          font-size: 24px;
          letter-spacing: 1px;
          color: white;
        }
      }

      .hideElectionDayMsg {
        display: none;
      }
  
      .electionDayMsgContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 775px;
        height: 36px;
        margin: 0px 10px 0px 10px;
        background-color: blue;
        border-style: solid;
        border-width: 1.5px;
        border-color: white;
        border-radius: 10px;
  
        .electionDayMsg {
          flex-wrap: wrap;
          text-align: center;
          font-size: 24px;
          letter-spacing: 1px;
          color: white;
        }
      }
    }

    .specialMsgMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      height: 150px;
      background-image: url('../../images/countdownFlag.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      justify-content: space-evenly;
      align-items: center;

      .specialMsgRow1 {
        display: flex;
        position: relative;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        // margin: 15px 0px 7.5px 0px;

        .ballotRequestLink {
          display: flex;
          position: relative;
          flex-direction: column;
          // width: 750px;
          // height: 85px;
          width: 475px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // width: 750px;
            // height: 85px;
            width: 475px;
            height: 55px;
            background-color: blue;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }

            .specialMsgSpan {
              display: flex;
              position: relative;
              width: 90%;
              height: 4px;
              margin: 8px 0px;
              background-color: red;
              border-style: solid;
              border-radius: 5px;
              border-color: white;
              border-width: 0.5px;
            }
          }
        }

        .ballotRequestLink:active {
          display: flex;
          position: relative;
          flex-direction: column;
          // width: 750px;
          // height: 85px;
          width: 475px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // width: 750px;
            // height: 85px;
            width: 475px;
            height: 55px;
            background-color: red;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }

            .specialMsgSpan {
              display: flex;
              position: relative;
              width: 90%;
              height: 4px;
              margin: 8px 0px;
              background-color: blue;
              border-style: solid;
              border-radius: 5px;
              border-color: white;
              border-width: 0.5px;
            }
          }
        }

        .ballotTrackLink {
          display: flex;
          position: relative;
          flex-direction: column;
          width: 475px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 475px;
            height: 55px;
            background-color: blue;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }
          }
        }

        .ballotTrackLink:active {
          display: flex;
          position: relative;
          flex-direction: column;
          width: 475px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 475px;
            height: 55px;
            background-color: red;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }
          }
        }
      }

      .specialMsgRow2 {
        display: flex;
        position: relative;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        // margin: 7.5px 0px 15px 0px;

        .yardSignLink {
          display: flex;
          position: relative;
          flex-direction: column;
          // width: 750px;
          // height: 85px;
          width: 450px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // width: 750px;
            // height: 85px;
            width: 450px;
            height: 55px;
            background-color: blue;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }

            .specialMsgSpan {
              display: flex;
              position: relative;
              width: 90%;
              height: 4px;
              margin: 8px 0px;
              background-color: red;
              border-style: solid;
              border-radius: 5px;
              border-color: white;
              border-width: 0.5px;
            }
          }
        }

        .yardSignLink:active {
          display: flex;
          position: relative;
          flex-direction: column;
          // width: 750px;
          // height: 85px;
          width: 450px;
          height: 55px;
          justify-content: center;
          align-items: center;
          text-decoration: none;

          .specialMsgContainer {
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // width: 750px;
            // height: 85px;
            width: 450px;
            height: 55px;
            background-color: red;
            border-style: solid;
            border-width: 1.5px;
            border-color: white;
            border-radius: 10px;

            .specialMsg {
              display: flex;
              position: relative;
              flex-wrap: wrap;
              font-family: sans-serif;
              font-size: 24px;
              letter-spacing: 1px;
              line-height: 30px;
              text-align: center;
              color: white;
            }

            .specialMsgSpan {
              display: flex;
              position: relative;
              width: 90%;
              height: 4px;
              margin: 8px 0px;
              background-color: blue;
              border-style: solid;
              border-radius: 5px;
              border-color: white;
              border-width: 0.5px;
            }
          }
        }
      }
    }

    .homepageHeaderImg {
      width: 100vw;
    }
  }

  .reasonContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;

    .reasonTitleContainer {
      margin: 0px 0px 15px 0px;

      .reasonTitle {
        font-family: sans-serif;
        font-size: 50px;
        font-weight: bold;
        letter-spacing: 2px;
        color: #20638c;
      }
    }

    .reasonParagraphContainer {
      justify-content: center;
      align-items: center;

      .reasonParagraph {
        line-height: 36px;
        font-family: sans-serif;
        font-size: 24px;
        text-align: center;
        letter-spacing: 1px;
      }

      .hashtagLogo {
        margin-top: 25px;
        line-height: 35px;
        font-family: sans-serif;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
        color: white;
        text-shadow: 0 0 15px black;
        text-shadow: 2px 2px 0 #20638c, 2px -2px 0 #20638c, -2px 2px 0 #20638c,
          -2px -2px 0 #20638c, 2px 0px 0 #20638c, 0px 2px 0 #20638c,
          -2px 0px 0 #20638c, 0px -2px 0 #20638c, 2px 2px 0 rgba(0, 0, 255, 0);
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 90%;
    height: 5px;
    border-radius: 5px;
    background-color: #20638c;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }

  .mediaMainContainer {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;

    .campaignVideosMainContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      .campaignVideosTitleContainer {
        margin-bottom: 35px;

        .campaignVideosTitle {
          font-family: sans-serif;
          font-size: 50px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .campaignVideoContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 75px;

        .videoTitleContainer {
          display: flex;
          position: relative;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;

          .videoTitle {
            font-family: sans-serif;
            font-size: 24px;
            line-height: 30px;
            font-weight: bold;
            letter-spacing: 1px;
            text-align: center;
            color: black;
          }
        }

        .campaignVideo {
          width: 576px;
          height: 324px;
        }
      }
    }

    .separator {
      display: flex;
      position: relative;
      width: 5px;
      height: 96%;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    }

    .facebookFeedsMainContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      .facebookFeedsTitleContainer {
        margin-bottom: 35px;

        .facebookFeedsTitle {
          font-family: sans-serif;
          font-size: 50px;
          font-weight: bold;
          letter-spacing: 2px;
          color: #20638c;
        }
      }

      .facebookFeedsContainer {
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .homepageContainer {
    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 40px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 32px;
          font-size: 22px;
        }
      }
    }

    .mediaMainContainer {
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;

      .campaignVideosMainContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 35px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 75px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 24px;
              line-height: 30px;
              text-align: center;
            }
          }

          .campaignVideo {
            width: 464px;
            height: 261px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 5px;
        height: 96%;
        border-radius: 5px;
        background-color: #20638c;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 35px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .homepageContainer {
    margin-top: 0px;
    .homepageHeaderContainer {
      .countdownMainContainer {
        height: 155px;

        .voteCountdownContainer {
          margin: 7.5px 0px;

          .counterMainContainer {
            width: 85px;
            margin: 0px 10px 0px 10px;
            border-radius: 7.5px;

            .counterContainer {
              width: 48px;
              height: 54px;
              border-radius: 5px;
              margin: 7.5px 0px;

              .counterNum {
                font-size: 30px;
              }
            }

            .counterTitleContainer {
              margin-bottom: 7.5px;

              .counterTitle {
                font-size: 20px;
              }
            }
          }
        }
        .voteMsgContainer {
          width: 655px;
          height: 30px;
          margin: 0px 10px 0px 10px;
          border-radius: 7.5px;

          .voteMsg {
            font-size: 20px;
          }
        }
      }

      .specialMsgMainContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 175px;
        background-image: url('../../images/countdownFlag.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        // justify-content: center;
        justify-content: space-evenly;
        align-items: center;

        .specialMsgRow1 {
          .ballotRequestLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 325px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 8px 0px;
                background-color: red;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotRequestLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 325px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 8px 0px;
                background-color: blue;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotTrackLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 325px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }
            }
          }

          .ballotTrackLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 325px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }
            }
          }
        }
        .specialMsgRow2 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .yardSignLink {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 325px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }
            }
          }

          .yardSignLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 325px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 325px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 30px;
              }
            }
          }
        }
      }
    }

    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 30px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 30px;
          font-size: 20px;
        }

        .hashtagLogo {
          line-height: 30px;
          font-size: 30px;
        }
      }
    }

    .mediaMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      .campaignVideosMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 35px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 50px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 24px;
              line-height: 30px;
            }
          }

          .campaignVideo {
            width: 576px;
            height: 324px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        margin: 0px 0px 30px 0px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .homepageContainer {
    margin-top: 0px;

    .homepageHeaderContainer {
      .countdownMainContainer {
        height: 185px;

        .voteCountdownContainer {
          margin: 7.5px 0px;

          .counterMainContainer {
            width: 85px;
            margin: 0px 10px 0px 10px;
            border-radius: 7.5px;

            .counterContainer {
              width: 48px;
              height: 54px;
              border-radius: 5px;
              margin: 7.5px 0px;

              .counterNum {
                font-size: 30px;
              }
            }

            .counterTitleContainer {
              margin-bottom: 7.5px;

              .counterTitle {
                font-size: 20px;
              }
            }
          }
        }
        .voteMsgContainer {
          width: 450px;
          height: 55px;
          margin: 0px 10px 0px 10px;
          border-radius: 7.5px;

          .voteMsg {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .specialMsgMainContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 175px;
        background-image: url('../../images/countdownFlag.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        // justify-content: center;
        justify-content: space-evenly;
        align-items: center;

        .specialMsgRow1 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .ballotRequestLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 275px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 20px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 97%;
                height: 4px;
                margin: 8px 0px;
                background-color: red;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotRequestLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 275px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 20px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 97%;
                height: 4px;
                margin: 8px 0px;
                background-color: blue;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotTrackLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 275px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 20px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }
            }
          }

          .ballotTrackLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 275px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }
            }
          }
        }

        .specialMsgRow2 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .yardSignLink {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 275px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 20px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }
            }
          }

          .yardSignLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 275px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 275px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 20px;
                letter-spacing: 1px;
                color: white;
                line-height: 26px;
              }
            }
          }
        }
      }
    }

    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 30px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 30px;
          font-size: 20px;
        }

        .hashtagLogo {
          line-height: 30px;
          font-size: 30px;
        }
      }
    }

    .mediaMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      .campaignVideosMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 30px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 50px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 20px;
              line-height: 25px;
            }
          }

          .campaignVideo {
            width: 560px;
            height: 315px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        margin: 0px 0px 30px 0px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .homepageContainer {
    margin-top: 0px;

    .homepageHeaderContainer {
      .countdownMainContainer {
        height: 185px;

        .voteCountdownContainer {
          margin: 7.5px 0px;

          .counterMainContainer {
            width: 75px;
            margin: 0px 7.5px 0px 7.5px;
            border-radius: 7.5px;

            .counterContainer {
              width: 40px;
              height: 44px;
              border-radius: 5px;
              margin: 7.5px 0px;

              .counterNum {
                font-size: 24px;
              }
            }

            .counterTitleContainer {
              margin-bottom: 7.5px;

              .counterTitle {
                font-size: 18px;
              }
            }
          }
        }
        .voteMsgContainer {
          width: 385px;
          height: 55px;
          margin: 0px 10px 0px 10px;
          border-radius: 7.5px;

          .voteMsg {
            font-size: 18px;
            line-height: 20px;
          }
        }
      }

      .specialMsgMainContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 175px;
        background-image: url('../../images/countdownFlag.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        // justify-content: center;
        justify-content: space-evenly;
        align-items: center;

        .specialMsgRow1 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .ballotRequestLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 200px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: red;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotRequestLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 200px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: blue;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotTrackLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 200px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .ballotTrackLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 200px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }

        .specialMsgRow2 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .yardSignLink {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 75px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .yardSignLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 75px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 75px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 24px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 30px;
          font-size: 18px;
        }

        .hashtagLogo {
          line-height: 30px;
          font-size: 24px;
        }
      }
    }

    .mediaMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      .campaignVideosMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 24px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 50px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 20px;
              line-height: 25px;
            }
          }

          .campaignVideo {
            width: 400px;
            height: 225px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        margin: 0px 0px 30px 0px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .homepageContainer {
    margin-top: 0px;

    .homepageHeaderContainer {
      .countdownMainContainer {
        height: 170px;

        .voteCountdownContainer {
          margin: 7.5px 0px;

          .counterMainContainer {
            width: 70px;
            margin: 0px 7.5px 0px 7.5px;
            border-radius: 7.5px;

            .counterContainer {
              width: 36px;
              height: 40px;
              border-radius: 5px;
              margin: 7.5px 0px;

              .counterNum {
                font-size: 20px;
              }
            }

            .counterTitleContainer {
              margin-bottom: 7.5px;

              .counterTitle {
                font-size: 16px;
              }
            }
          }
        }
        .voteMsgContainer {
          width: 340px;
          height: 55px;
          margin: 0px 10px 0px 10px;
          border-radius: 7.5px;

          .voteMsg {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .specialMsgMainContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 175px;
        background-image: url('../../images/countdownFlag.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        // justify-content: center;
        justify-content: space-evenly;
        align-items: center;

        .specialMsgRow1 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .ballotRequestLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 1px;
                color: white;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: red;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotRequestLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 1px;
                color: white;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: blue;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotTrackLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .ballotTrackLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }

        .specialMsgRow2 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .yardSignLink {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 65px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 65px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .yardSignLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 65px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 65px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 24px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 24px;
          font-size: 18px;
        }

        .hashtagLogo {
          line-height: 30px;
          font-size: 24px;
        }
      }
    }

    .mediaMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      .campaignVideosMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 24px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 30px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 18px;
              line-height: 25px;
            }
          }

          .campaignVideo {
            width: 336px;
            height: 189px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        margin: 0px 0px 30px 0px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}

@media only screen and (max-width: 359px) {
  .homepageContainer {
    margin-top: 0px;
    .homepageHeaderContainer {
      .countdownMainContainer {
        height: 170px;

        .voteCountdownContainer {
          margin: 7.5px 0px;

          .counterMainContainer {
            width: 65px;
            margin: 0px 7.5px 0px 7.5px;
            border-radius: 7.5px;

            .counterContainer {
              width: 36px;
              height: 40px;
              border-radius: 5px;
              margin: 7.5px 0px;

              .counterNum {
                font-size: 20px;
              }
            }

            .counterTitleContainer {
              margin-bottom: 7.5px;

              .counterTitle {
                font-size: 16px;
              }
            }
          }
        }

        .voteMsgContainer {
          width: 300px;
          height: 55px;
          margin: 0px 10px 0px 10px;
          border-radius: 7.5px;

          .voteMsg {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .specialMsgMainContainer {
        display: flex;
        position: relative;
        flex-direction: column;
        width: 100%;
        height: 175px;
        background-image: url('../../images/countdownFlag.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        // justify-content: center;
        justify-content: space-evenly;
        align-items: center;

        .specialMsgRow1 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .ballotRequestLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 1px;
                color: white;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: red;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotRequestLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                line-height: 24px;
                letter-spacing: 1px;
                color: white;
              }

              .specialMsgSpan {
                display: flex;
                position: relative;
                width: 95%;
                height: 4px;
                margin: 5px 0px;
                background-color: blue;
                border-style: solid;
                border-radius: 5px;
                border-color: white;
                border-width: 0.5px;
              }
            }
          }

          .ballotTrackLink {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .ballotTrackLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            width: 140px;
            height: 85px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 140px;
              height: 85px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }

        .specialMsgRow2 {
          display: flex;
          position: relative;
          flex-direction: row;
          width: 100%;
          justify-content: space-evenly;
          align-items: center;
          // margin: 7.5px 0px 15px 0px;

          .yardSignLink {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 65px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 65px;
              background-color: blue;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }

          .yardSignLink:active {
            display: flex;
            position: relative;
            flex-direction: column;
            // width: 750px;
            // height: 85px;
            width: 200px;
            height: 65px;
            justify-content: center;
            align-items: center;
            text-decoration: none;

            .specialMsgContainer {
              display: flex;
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              // width: 750px;
              // height: 85px;
              width: 200px;
              height: 65px;
              background-color: red;
              border-style: solid;
              border-width: 1.5px;
              border-color: white;
              border-radius: 10px;

              .specialMsg {
                flex-wrap: wrap;
                text-align: center;
                font-size: 18px;
                letter-spacing: 1px;
                color: white;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .reasonContainer {
      .reasonTitleContainer {
        .reasonTitle {
          font-size: 24px;
        }
      }

      .reasonParagraphContainer {
        .reasonParagraph {
          line-height: 24px;
          font-size: 18px;
        }

        .hashtagLogo {
          line-height: 30px;
          font-size: 24px;
        }
      }
    }

    .mediaMainContainer {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;

      .campaignVideosMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;

        .campaignVideosTitleContainer {
          margin-bottom: 20px;

          .campaignVideosTitle {
            font-size: 24px;
          }
        }

        .campaignVideoContainer {
          margin-bottom: 30px;

          .videoTitleContainer {
            margin-bottom: 10px;

            .videoTitle {
              font-size: 18px;
              line-height: 25px;
            }
          }

          .campaignVideo {
            width: 304px;
            height: 171px;
          }
        }
      }

      .separator {
        display: flex;
        position: relative;
        width: 90%;
        height: 5px;
        margin: 0px 0px 30px 0px;
      }

      .facebookFeedsMainContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: auto;

        .facebookFeedsTitleContainer {
          margin-bottom: 20px;

          .facebookFeedsTitle {
            font-family: sans-serif;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 2px;
            color: #20638c;
          }
        }

        .facebookFeedsContainer {
        }
      }
    }
  }
}
