.votingPageContainer {
  display: flex;
  position: relative;
  z-index: 500;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;

  .districtInfoContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .districtDescriptionContainer {
      margin-top: 25px;

      .districtDescription {
        font-size: 20px;
        font-family: sans-serif;
        text-align: center;
        line-height: 27px;
      }
    }

    .districtCheckContainer {
      margin-top: 25px;
      text-align: center;

      .districtCheck {
        font-size: 20px;
        line-height: 27px;
        font-family: sans-serif;
      }

      .districtCheckLink {
        margin-left: 7px;
        margin-right: 7px;
        font-size: 20px;
        font-family: sans-serif;
        font-weight: bold;
        text-decoration: none;
        color: red;
      }
    }

    .districtImgContainer {
      margin-top: 15px;

      .districtImg {
        width: 600px;
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 5px;
    height: 95%;
    border-radius: 5px;
    background-color: #20638c;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }

  .voterInfoContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .voterInfoBanner {
      font-size: 50px;
      font-family: sans-serif;
      font-weight: bold;
      text-align: center;
      line-height: 60px;
      color: #20638c;
    }

    .voterInfo {
      margin-top: 75px;
      font-size: 30px;
      font-family: sans-serif;
      text-align: center;

      .voterInfoLink {
        margin-right: 12px;
        font-size: 30px;
        font-family: sans-serif;
        text-decoration: none;
        color: red;
      }
    }

    .electionInfo {
      margin-top: 75px;
      font-size: 24px;
      line-height: 30px;
      font-family: sans-serif;
      text-align: center;

      .electionInfoLink {
        margin-left: 7px;
        font-size: 24px;
        font-family: sans-serif;
        text-decoration: none;
        color: red;
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .votingPageContainer {
    .districtInfoContainer {
      .districtCheckContainer {
        .districtCheck {
          font-size: 20px;
        }
        .districtCheckLink {
          margin-left: 7px;
          margin-right: 7px;
          font-size: 20px;
        }
      }

      .districtImgContainer {
        .districtImg {
          width: 450px;
        }
      }
    }

    .separator {
      margin: 30px 15px 30px 15px;
    }

    .voterInfoContainer {
      .voterInfoBanner {
        font-size: 30px;
        line-height: 40px;
      }

      .voterInfo {
        font-size: 25px;

        .voterInfoLink {
          margin-right: 7px;
          font-size: 25px;
        }
      }

      .electionInfo {
        font-size: 25px;

        .electionInfoLink {
          font-size: 25px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .votingPageContainer {
    flex-direction: column;
    justify-content: center;

    .districtInfoContainer {
      .districtDescriptionContainer {
        margin-top: 25px;

        .districtDescription {
          font-size: 20px;
          line-height: 35px;
        }
      }

      .districtCheckContainer {
        margin-top: 25px;

        .districtCheck {
          line-height: 35px;
        }
      }

      .districtImgContainer {
        .districtImg {
          width: 550px;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
      border-radius: 5px;
    }

    .voterInfoContainer {
      .voterInfoBanner {
        font-size: 30px;
        line-height: 40px;
      }

      .voterInfo {
        margin-top: 50px;
        font-size: 20px;

        .voterInfoLink {
          margin-right: 7px;
          font-size: 20px;
        }
      }

      .electionInfo {
        margin: 50px 0px 20px 0px;
        font-size: 20px;
        line-height: 30px;

        .electionInfoLink {
          margin-left: 7px;
          font-size: 20px;
          font-family: sans-serif;
          text-decoration: none;
          color: red;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .votingPageContainer {
    flex-direction: column;
    justify-content: center;

    .districtInfoContainer {
      .districtDescriptionContainer {
        margin-top: 25px;

        .districtDescription {
          font-size: 20px;
          line-height: 35px;
        }
      }

      .districtCheckContainer {
        margin-top: 25px;

        .districtCheck {
          line-height: 35px;
        }
      }

      .districtImgContainer {
        .districtImg {
          width: 500px;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
      border-radius: 5px;
    }

    .voterInfoContainer {
      .voterInfoBanner {
        font-size: 30px;
        line-height: 40px;
      }

      .voterInfo {
        margin-top: 50px;
        font-size: 20px;

        .voterInfoLink {
          margin-right: 7px;
          font-size: 20px;
        }
      }

      .electionInfo {
        margin: 50px 0px 20px 0px;
        font-size: 20px;
        line-height: 30px;

        .electionInfoLink {
          margin-left: 7px;
          font-size: 20px;
          font-family: sans-serif;
          text-decoration: none;
          color: red;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .votingPageContainer {
    flex-direction: column;
    justify-content: center;

    .districtInfoContainer {
      .districtDescriptionContainer {
        margin-top: 25px;

        .districtDescription {
          font-size: 18px;
          line-height: 30px;
        }
      }

      .districtCheckContainer {
        margin-top: 25px;

        .districtCheck {
          font-size: 18px;
          line-height: 30px;
        }

        .districtCheckLink {
          font-size: 18px;
        }
      }

      .districtImgContainer {
        .districtImg {
          width: 95%;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
      border-radius: 5px;
    }

    .voterInfoContainer {
      .voterInfoBanner {
        font-size: 24px;
        line-height: 30px;
      }

      .voterInfo {
        margin-top: 50px;
        font-size: 18px;

        .voterInfoLink {
          margin-right: 7px;
          font-size: 18px;
        }
      }

      .electionInfo {
        margin: 50px 0px 20px 0px;
        font-size: 18px;
        line-height: 30px;

        .electionInfoLink {
          margin-left: 7px;
          font-size: 18px;
          font-family: sans-serif;
          text-decoration: none;
          color: red;
        }
      }
    }
  }
}
