.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #20638c;
  width: 100%;
  height: 100%;

  .authorizationContainer {
    margin: 10px 10px 10px 10px;

    .authorization {
      font-family: sans-serif;
      font-size: 15px;
      letter-spacing: 2px;
      color: white;
      text-align: center;
    }
  }

  .socialMediaContainer {
    .socialMediaLink {
      outline: none;
      text-decoration: none;
      cursor: pointer;

      .socialMediaIcon {
        margin: 0px 10px;
      }
    }
  }

  .reportsContainer {
    margin: 10px 10px 25px 10px;

    .reports {
      font-family: sans-serif;
      font-size: 15px;
      letter-spacing: 2px;
      color: white;
      text-align: center;
      line-height: 25px;

      .reportsLink {
        text-decoration: none;
        outline: none;
        color: white;
        cursor: pointer;
        margin: 0px 2px 0px 2px;
      }
    }
  }

  .developerContainer {
    .developerInfo {
      font-family: sans-serif;
      font-size: 15px;
      line-height: 25px;
      letter-spacing: 2px;
      color: white;
      text-align: center;
      margin-bottom: 10px;

      .developerLink {
        font-weight: bold;
        text-decoration: none;
        outline: none;
        color: #ffffff;
        text-shadow: 0.75px 0.75px 0 #e10000, 0.75px -0.75px 0 #e10000,
          -0.75px 0.75px 0 #e10000, -0.75px -0.75px 0 #e10000,
          0.75px 0px 0 #e10000, 0px 0.75px 0 #e10000, -0.75px 0px 0 #e10000,
          0px -0.75px 0 #e10000;
        // color: rgb(225, 0, 0);
        // text-shadow: 0.5px 0.5px 0 #ffffff, 0.5px -0.5px 0 #ffffff,
        //   -0.5px 0.5px 0 #ffffff, -0.5px -0.5px 0 #ffffff, 0.5px 0px 0 #ffffff,
        //   0px 0.5px 0 #ffffff, -0.5px 0px 0 #ffffff, 0px -0.5px 0 #ffffff;
        cursor: pointer;
        margin: 0px 0px 0px 5px;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .footerContainer {
    .socialMediaContainer {
      .socialMediaLink {
        .socialMediaIcon {
          height: 35px;
        }
      }
    }
  }
}
