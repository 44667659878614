.contactPageContainer {
  display: flex;
  position: relative;
  z-index: 500;
  flex-direction: row;
  background-color: #20638c;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 0px 10px 0px;

  .showEmailMsg {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 75px);
    top: 75px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    color: rgb(0, 200, 0);
    cursor: pointer;
  }

  .hideEmailMsg {
    display: none;
  }

  .showEmailErr {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 75px);
    top: 75px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: orange;
    cursor: pointer;
  }

  .hideEmailErr {
    display: none;
  }

  .showEmailWarn {
    display: flex;
    position: fixed;
    z-index: 750;
    width: 100%;
    height: calc(100vh - 75px);
    top: 75px;
    background: rgba(50, 50, 50, 0.9);
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: none;
    color: rgb(225, 0, 0);
    cursor: pointer;
  }

  .hideEmailWarn {
    display: none;
  }

  .contactMsgContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;

    .contactMsg {
      line-height: 50px;
      font-family: sans-serif;
      font-size: 24px;
      font-weight: bold;
      text-align: left;
      letter-spacing: 1px;
      color: white;
    }

    .linkSeparator {
      display: flex;
      position: relative;
      width: 95%;
      height: 5px;
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.25);
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 20px 0px 20px 0px;
    }

    .countyProfileContainer {
      margin-top: 15px;
      text-align: center;

      .countyProfile {
        line-height: 35px;
        font-family: sans-serif;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 1px;
        color: white;
      }

      .countyProfileLink {
        margin-left: 7px;
        margin-right: 7px;
        font-size: 22px;
        font-family: sans-serif;
        font-weight: bold;
        text-decoration: none;
        color: #a61f38;
        text-shadow: 0 0 5px white;
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 5px;
    height: 90%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.25);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }

  .contactFormContainer {
    display: flex;
    flex-direction: column;

    .contactInputContainer1 {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .contactTitle1 {
        font-family: sans-serif;
        font-size: 25px;
        font-weight: bold;
        text-align: left;
        letter-spacing: 1px;
        color: white;
        text-shadow: 0 0 15px black;
      }

      .contactInput1 {
        cursor: text;
        outline: none;
        display: flex;
        position: relative;
        width: 500px;
        margin: 10px 0px 20px 10px;
        border-style: inset;
        border-radius: 8px;
        border-width: 2px;
        border-color: initial;
        padding: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-align: center;
        color: black;
      }
    }

    .contactInputContainer2 {
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .contactTitle2 {
        font-family: sans-serif;
        font-weight: bold;
        font-size: 25px;
        text-align: left;
        letter-spacing: 1px;
        color: white;
        text-shadow: 0 0 15px black;
      }

      .contactInput2 {
        cursor: text;
        outline: none;
        display: flex;
        position: relative;
        width: 500px;
        height: 250px;
        margin: 10px 0px 20px 10px;
        border-style: inset;
        border-color: initial;
        border-width: 2px;
        border-radius: 8px;
        padding: 5px;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        text-align: left;
        color: black;
        resize: none;
      }
    }

    .contactSubmitBtn {
      background-color: transparent;
      border: none;
      font-size: 30px;
      text-decoration: none;
      font-family: sans-serif;
      font-weight: bold;
      color: #00bb9f;
      text-shadow: 0 0 15px black;
      cursor: pointer;
      outline: none;
    }

    .contactSubmitBtn:hover {
      font-size: 33px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contactPageContainer {
    .showEmailMsg {
      font-size: 30px;
    }

    .showEmailErr {
      font-size: 30px;
    }

    .showEmailWarn {
      font-size: 30px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contactPageContainer {
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px 10px 0px;

    .showEmailMsg {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailErr {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailWarn {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .contactMsgContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .contactMsg {
        line-height: 35px;
        font-size: 25px;
      }

      .linkSeparator {
        width: 95%;
        height: 5px;
        margin: 20px 0px 20px 0px;
      }
  
      .countyProfileContainer {
        margin-top: 15px;
        text-align: center;
  
        .countyProfile {
          line-height: 35px;
          font-family: sans-serif;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
          color: white;
        }
  
        .countyProfileLink {
          margin-left: 7px;
          margin-right: 7px;
          font-size: 22px;
          font-family: sans-serif;
          font-weight: bold;
          text-decoration: none;
          color: #a61f38;
          text-shadow: 0 0 5px white;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
    }

    .contactFormContainer {
      justify-content: center;
      align-items: center;
      width: 100%;

      .contactInputContainer1 {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .contactInput1 {
          margin: 10px 0px 30px 0px;
        }
      }

      .contactInputContainer2 {
        align-items: center;

        .contactInput2 {
          margin: 10px 0px 30px 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .contactPageContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px 10px 0px;

    .showEmailMsg {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailErr {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailWarn {
      font-size: 30px;
      line-height: 35px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .contactMsgContainer {
      width: 90%;

      .contactMsg {
        line-height: 35px;
        font-size: 25px;
      }

      .linkSeparator {
        display: flex;
        position: relative;
        width: 95%;
        height: 5px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.25);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
        margin: 20px 0px 20px 0px;
      }
  
      .countyProfileContainer {
        margin-top: 15px;
        text-align: center;
  
        .countyProfile {
          line-height: 35px;
          font-family: sans-serif;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
          color: white;
        }
  
        .countyProfileLink {
          margin-left: 7px;
          margin-right: 7px;
          font-size: 22px;
          font-family: sans-serif;
          font-weight: bold;
          text-decoration: none;
          color: #a61f38;
          text-shadow: 0 0 5px white;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
    }

    .contactFormContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .contactInputContainer1 {
        .contactInput1 {
          margin: 10px 0px 30px 0px;
        }
      }

      .contactInputContainer2 {
        .contactInput2 {
          margin: 10px 0px 30px 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .contactPageContainer {
    flex-direction: column;
    justify-content: center;
    padding: 10px 0px 10px 0px;

    .showEmailMsg {
      font-size: 18px;
      line-height: 30px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailErr {
      font-size: 18px;
      line-height: 30px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .showEmailWarn {
      font-size: 18px;
      line-height: 30px;
      height: calc(100vh - 105px);
      top: 105px;
    }

    .contactMsgContainer {
      width: 90%;

      .contactMsg {
        line-height: 30px;
        font-size: 18px;
      }

      .linkSeparator {
        display: flex;
        position: relative;
        width: 95%;
        height: 5px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.25);
        box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
        margin: 20px 0px 20px 0px;
      }
  
      .countyProfileContainer {
        margin-top: 15px;
        text-align: center;
  
        .countyProfile {
          line-height: 30px;
          font-family: sans-serif;
          font-size: 18px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 1px;
          color: white;
        }
  
        .countyProfileLink {
          margin-left: 7px;
          margin-right: 7px;
          font-size: 18px;
          font-family: sans-serif;
          font-weight: bold;
          text-decoration: none;
          color: #a61f38;
          text-shadow: 0 0 5px white;
        }
      }
    }

    .separator {
      width: 95%;
      height: 5px;
    }

    .contactFormContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .contactInputContainer1 {
        justify-content: center;
        align-items: center;
        width: 100%;

        .contactInput1 {
          width: 90%;
          margin: 10px 0px 25px 0px;
        }
      }

      .contactInputContainer2 {
        justify-content: center;
        align-items: center;
        width: 100%;

        .contactInput2 {
          width: 90%;
          margin: 10px 0px 25px 0px;
        }
      }

      .contactSubmitBtn {
        font-size: 25px;
      }

      .contactSubmitBtn:hover {
        font-size: 27px;
      }
    }
  }
}
