.issuesPageContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  .mobileIssueSectionContainer {
    display: none;
  }

  .issueSectionContainer {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;

    .issueImgContainer {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 25px;

      .issueImg {
        margin-bottom: 25px;
        height: 250px;
        border-radius: 15px;
      }

      .issueImgTitle {
        line-height: 50px;
        font-family: sans-serif;
        font-size: 40px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 2px;
      }
    }

    .issueParagraphContainer {
      width: 60%;
      justify-content: center;
      align-items: center;

      .issueParagraph {
        margin: 10px;
        line-height: 40px;
        font-family: sans-serif;
        font-size: 20px;
        text-align: left;
        letter-spacing: 1px;
      }
    }
  }

  .separator {
    display: flex;
    position: relative;
    width: 90%;
    height: 5px;
    border-radius: 5px;
    background-color: #20638c;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
    margin: 30px 0px 30px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .issuesPageContainer {
    .issueSectionContainer {
      .issueImgContainer {
        margin: 20px;

        .issueImg {
          margin-bottom: 20px;
          height: 200px;
        }

        .issueImgTitle {
          line-height: 40px;
          font-size: 30px;
        }
      }

      .issueParagraphContainer {
        .issueParagraph {
          line-height: 35px;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .issuesPageContainer {
    .mobileIssueSectionContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .mobileIssueImgContainer {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .mobileIssueImg {
          margin-bottom: 15px;
          height: 200px;
          border-radius: 15px;
        }

        .mobileIssueImgTitle {
          line-height: 40px;
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
        }
      }

      .mobileIssueParagraphContainer {
        width: 90%;
        justify-content: center;
        align-items: center;

        .mobileIssueParagraph {
          margin: 10px;
          line-height: 35px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileSeparator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .issueSectionContainer {
      display: none;
    }

    .separator {
      display: none;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .issuesPageContainer {
    .mobileIssueSectionContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .mobileIssueImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .mobileIssueImg {
          margin-bottom: 15px;
          height: 200px;
          border-radius: 15px;
        }

        .mobileIssueImgTitle {
          line-height: 40px;
          font-family: sans-serif;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
        }
      }

      .mobileIssueParagraphContainer {
        width: 90%;
        justify-content: center;
        align-items: center;

        .mobileIssueParagraph {
          margin: 10px;
          line-height: 35px;
          font-family: sans-serif;
          font-size: 20px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileSeparator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .issueSectionContainer {
      display: none;
    }

    .separator {
      display: none;
    }
  }
}

@media only screen and (min-width: 414px) and (max-width: 599px) {
  .issuesPageContainer {
    .mobileIssueSectionContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .mobileIssueImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .mobileIssueImg {
          margin-bottom: 15px;
          height: 100px;
          border-radius: 10px;
        }

        .mobileIssueImgTitle {
          line-height: 30px;
          font-family: sans-serif;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
        }
      }

      .mobileIssueParagraphContainer {
        width: 90%;
        justify-content: center;
        align-items: center;

        .mobileIssueParagraph {
          margin: 10px;
          line-height: 30px;
          font-family: sans-serif;
          font-size: 18px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileSeparator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .issueSectionContainer {
      display: none;
    }

    .separator {
      display: none;
    }
  }
}

@media only screen and (min-width: 360px) and (max-width: 413px) {
  .issuesPageContainer {
    .mobileIssueSectionContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .mobileIssueImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .mobileIssueImg {
          margin-bottom: 15px;
          height: 100px;
          border-radius: 10px;
        }

        .mobileIssueImgTitle {
          line-height: 30px;
          font-family: sans-serif;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
        }
      }

      .mobileIssueParagraphContainer {
        width: 90%;
        justify-content: center;
        align-items: center;

        .mobileIssueParagraph {
          margin: 10px;
          line-height: 30px;
          font-family: sans-serif;
          font-size: 18px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileSeparator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .issueSectionContainer {
      display: none;
    }

    .separator {
      display: none;
    }
  }
}

@media only screen and (max-width: 359px) {
  .issuesPageContainer {
    .mobileIssueSectionContainer {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .mobileIssueImgContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 15px;

        .mobileIssueImg {
          margin-bottom: 15px;
          height: 100px;
          border-radius: 10px;
        }

        .mobileIssueImgTitle {
          line-height: 30px;
          font-family: sans-serif;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 2px;
        }
      }

      .mobileIssueParagraphContainer {
        width: 90%;
        justify-content: center;
        align-items: center;

        .mobileIssueParagraph {
          margin: 10px;
          line-height: 30px;
          font-family: sans-serif;
          font-size: 18px;
          text-align: left;
          letter-spacing: 1px;
        }
      }
    }

    .mobileSeparator {
      display: flex;
      position: relative;
      width: 90%;
      height: 5px;
      border-radius: 5px;
      background-color: #20638c;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 5px;
      margin: 30px 0px 30px 0px;
    }

    .issueSectionContainer {
      display: none;
    }

    .separator {
      display: none;
    }
  }
}
